@font-face {
font-family: '__PPSupplyMono_ca767d';
src: url(/_next/static/media/f9ac909e4da1c3e0-s.p.otf) format('opentype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__PPSupplyMono_ca767d';
src: url(/_next/static/media/aae4c06610d09e19-s.p.otf) format('opentype');
font-display: swap;
font-weight: 500;
font-style: medium;
}

@font-face {
font-family: '__PPSupplyMono_ca767d';
src: url(/_next/static/media/7ff7e0f329e531ad-s.p.otf) format('opentype');
font-display: swap;
font-weight: 700;
font-style: bold;
}

@font-face {
font-family: '__PPSupplyMono_ca767d';
src: url(/_next/static/media/7d4fb347f5fec8ea-s.p.otf) format('opentype');
font-display: swap;
font-weight: 600;
font-style: semi-bold;
}@font-face {font-family: '__PPSupplyMono_Fallback_ca767d';src: local("Arial");ascent-override: 60.84%;descent-override: 15.21%;line-gap-override: 0.00%;size-adjust: 131.49%
}.__className_ca767d {font-family: '__PPSupplyMono_ca767d', '__PPSupplyMono_Fallback_ca767d'
}.__variable_ca767d {--font-number: '__PPSupplyMono_ca767d', '__PPSupplyMono_Fallback_ca767d'
}

